import { AgGridReact } from 'ag-grid-react';
import React, { useState, useEffect, useRef } from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { LicenseManager } from 'ag-grid-enterprise';
import { Button, ButtonGroup } from 'reactstrap';
import { formatDate, currencyFormatter, simpleNumber, currencyInCentsFormatter } from '#helpers/displayUtils.js';
import BaseAPI from '#helpers/base-api.js';
import TransactionTypeFilterPanel from './transactionGridComponents/TransactionTypeFilterPanel';
import DatePostedFilterPanel from './transactionGridComponents/DatePostedFilterPanel';
import enums from '#constants/enums';

if (process.env.REACT_APP_AG_GRID_LICENSE_KEY) {
    LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);
} else {
    console.warn('AG Grid license key not found in environment variables.');
}

const formatFilter = (filterModel) => {
    const formattedFilter = {};

    Object.keys(filterModel).forEach((columnName) => {
        const filterData = filterModel[columnName];
        if (filterData) {
            if (filterData.filterType === 'number') {
                formattedFilter[columnName] = {
                    type: filterData.type,
                    filter: filterData.filter,
                };
            } else {
                formattedFilter[columnName] = filterData.filter;
            }
        }
    });

    return formattedFilter;
};


const TransactionGrid = ({ userId }) => {
    const ebAdminClient = new BaseAPI();
    const gridRef = useRef();
    const [activeType, setActiveType] = useState('all'); // Liz asked to change this to 'all' from 'settled' on 1/2/2025
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Function to get user-friendly transaction type
    const getFriendlyTransactionType = ({ data }) => {
        if ((data.act_type === "AD") && (data.details.includes("Credit") || data.details.includes("Debit"))) {
            return "Adjustment";
        }
        return enums.ActivityTypeMap[data.act_type] || data.act_type;
    };

    const fetchTransactions = async ({ page, limit, type, filter }) => {
        setError(null);
        try {

            if (activeType === 'achRequests') {
                const endpoint = `users/${userId}/ach-requests`;
                const response = await ebAdminClient.get(endpoint);
                console.log(response)
                return response
            }
            if (activeType === 'userView') {
                const resp = await ebAdminClient.get(`fund/${userId}/card-usage-history?page=${page}&limit=20`);
                console.log("User View Response: ", resp)
                return resp

            }
            const endpoint = `/transactions/history/${userId}/${type}?page=${page}&limit=${limit}&filter=${JSON.stringify(filter)}&paginate=true`;
            const response = await ebAdminClient.get(endpoint);
            console.log("Response: ", response)

            return response
        } catch (err) {
            setError('Failed to fetch transactions. Please try again.');
            console.error('Error fetching transactions:', err);
        }
    };


    // Define column definition sets for different transaction types
    const columnDefs = {
        all: [
            {
                headerName: 'Merchant/Sender',
                field: 'details',
                width: 150,
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                filterParams: {
                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                headerName: 'MCC',
                field: 'mcc_code',
                valueFormatter: simpleNumber,
            },
            {
                headerName: 'Auth ID',
                field: 'auth_id',
                // cellDataType: 'number',
                valueFormatter: simpleNumber,
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                filterParams: {
                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                headerName: 'Transaction Type',
                field: 'act_type_description',
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                floatingFilterComponent: TransactionTypeFilterPanel,
                filterParams: {
                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                headerName: 'Date Posted',
                field: 'post_ts',
                valueFormatter: ({ value }) => formatDate(value),
                filter: 'agDateColumnFilter',
                floatingFilter: true,
                floatingFilterComponent: DatePostedFilterPanel,
                filterParams: {
                    filterOptions: ['equals'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                headerName: 'Date Authorized',
                field: 'auth_ts',
                valueFormatter: ({ value }) => formatDate(value),
                filter: 'agDateColumnFilter',
                floatingFilter: true,
                floatingFilterComponent: DatePostedFilterPanel,
                filterParams: {
                    filterOptions: ['equals'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                headerName: 'Amount',
                field: 'amt',
                valueFormatter: currencyFormatter,
                filter: 'agNumberColumnFilter',
                floatingFilter: true,
                filterParams: {
                    filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                headerName: 'Calculated Balance',
                field: 'calculated_balance',
                valueFormatter: currencyFormatter,
            },
        ],
        settled: [
            {
                headerName: 'Details',
                field: 'details',
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                headerName: 'MCC',
                field: 'mcc',
                valueFormatter: true,
                cellDataType: 'number',
            },
            // {
            //     headerName: 'Auth Id',
            //     field: 'auth_id',
            //     cellDataType: 'number',
            //     filter: true,
            //     valueFormatter: simpleNumber,
            // },
            {
                headerName: 'Description',
                field: 'description'
            },
            {
                headerName: 'Date Posted',
                field: 'post_ts',
                valueFormatter: ({ value }) => formatDate(value),
                filter: 'agDateColumnFilter',
                floatingFilter: true,
                floatingFilterComponent: DatePostedFilterPanel,
                filterParams: {
                    filterOptions: ['equal'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                headerName: 'Amount',
                field: 'amt',
                cellDataType: 'number',
                valueFormatter: currencyFormatter,
                filter: 'agNumberColumnFilter',
                floatingFilter: true,
                filterParams: {
                    filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
        ],
        authorizations: [
            {
                headerName: 'Merchant',
                field: 'details',
                filter: 'agTextColumnFilter',
                floatingFilter: true,
            },
            {
                headerName: 'MCC',
                field: 'mcc',
                cellDataType: 'number',
                valueFormatter: simpleNumber,
            },
            {
                headerName: 'Auth ID',
                field: 'auth_id',
                cellDataType: 'number',
                valueFormatter: simpleNumber,
                filter: 'agTextColumnFilter',
                floatingFilter: true,
            },
            {
                headerName: 'Date Authorized',
                field: 'timestamp',
                sortable: true,
                valueFormatter: ({ value }) => formatDate(value),
                filter: 'agDateColumnFilter',
                floatingFilter: true,
                floatingFilterComponent: DatePostedFilterPanel,
                filterParams: {
                    filterOptions: ['equal'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                headerName: 'Amount',
                field: 'amount',
                cellDataType: 'number',
                valueFormatter: currencyFormatter,
                filter: 'agNumberColumnFilter',
                floatingFilter: true,
                filterParams: {
                    filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
        ],
        payments: [
            {
                headerName: 'Source',
                field: 'details',
            },
            {
                headerName: 'Status Description',
                field: 'status_description'
            },
            {
                headerName: 'Details',
                field: 'details',
            },
            {
                headerName: 'Date Posted',
                field: 'timestamp',
                valueFormatter: ({ value }) => formatDate(value),
                filter: 'agDateColumnFilter',
                floatingFilter: true,
                floatingFilterComponent: DatePostedFilterPanel,
                filterParams: {
                    filterOptions: ['equal'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                headerName: 'Amount',
                field: 'amount',
                cellDataType: 'number',
                suppressSizeToFit: true,
                width: 50,
                valueFormatter: currencyFormatter,
                filter: 'agNumberColumnFilter',
                floatingFilter: true,
                filterParams: {
                    filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
        ],
        achRequests: [
            {
                headerName: 'Requested At',
                field: 'createdAt',
                valueFormatter: ({ value }) => {
                    if (!value) {
                        return 'No Date Available'
                    }
                    return formatDate(value)
                },
            },
            {
                headerName: 'Amount',
                field: 'amount',
                valueFormatter: currencyInCentsFormatter
            },
            {
                headerName: 'Transaction Type',
                field: 'edgeType',
            },
            {
                headerName: 'Source',
                field: 'originator.name',
                valueFormatter: (params) => {
                    return `${[
                        enums.AchEdgeType.DEPOSIT,
                        enums.AchEdgeType.MATCHED_DEPOSIT,
                        enums.AchEdgeType.REPAYMENT_AUTO,
                        enums.AchEdgeType.REPAYMENT_EARLY,
                        enums.AchEdgeType.REPAYMENT_LATE,
                        enums.AchEdgeType.REPAYMENT_MANUALLY,
                    ].includes(params.data.edgeType)
                        ? (params.data.bankAccount?.metaBankName || '') + ' ' + (params.data.bankAccount?.metaAccountMask || '')
                        : 'Cross River Bank'
                        }`
                }
            },
            {
                headerName: 'Destination',
                field: 'bankAccount.metaBankName',
                valueFormatter: (params) => {
                    return [enums.AchEdgeType.WITHDRAWAL].includes(params.data.edgeType)
                        ? (params.data.bankAccount?.metaBankName || '') + ' ' + (params.data.bankAccount?.metaAccountMask || '')
                        : 'Cross River Bank'
                }
            },
            {
                headerName: 'Starting Balance',
                field: 'bankBalance',
                valueFormatter: currencyFormatter
            },
            {
                headerName: 'Status',
                field: 'status',
            },
            {
                headerName: 'Sent/Completed At',
                field: 'completedAt',
                valueFormatter: (params) => (params.value ? formatDate(params.value) : '-'),
            },
            {
                headerName: 'Funds Released At',
                field: 'releasedAt',
                valueFormatter: (params) => (params.value ? formatDate(params.value) : '-'),
            }],
        userView: [
            {
                headerName: 'Date Authorized',
                field: 'auth_ts',
                valueFormatter: ({ value }) => formatDate(value),
                filter: 'agDateColumnFilter',
                floatingFilter: true,
                floatingFilterComponent: DatePostedFilterPanel,
                filterParams: {
                    filterOptions: ['equals'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
            {
                headerName: 'Details',
                field: 'details',
            },
            {
                headerName: 'Type',
                field: 'act_type',
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                floatingFilterComponent: TransactionTypeFilterPanel,
                filterParams: {
                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
                valueFormatter: (params) => {
                    return getFriendlyTransactionType(params)
                }
            },
            {
                headerName: 'Amount',
                field: 'amt',
                valueFormatter: currencyFormatter,
                filter: 'agNumberColumnFilter',
                floatingFilter: true,
                filterParams: {
                    filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
        ]
    };
    const defaultColDef = {
        flex: 1,
        minWidth: 10,
        resizable: true,
        sortable: false
    };

    const getTransactionsFromServer = () => {
        return {
            getRows: async (params) => {
                const pageSize = 200;
                const pageNumber = Math.floor(params.request.startRow / pageSize) + 1;
                // Filtering
                const filterModel = params.request.filterModel;
                const filter = formatFilter(filterModel);

                // Sorting 
                // const sortByString = params.request.sortModel[0]?.colId;
                // const sortByType = params.request.sortModel[0]?.sort;
                // let sortParams = `${sortByString}:${sortByType}`;
                // if (sortParams === 'undefined:undefined') {
                //     sortParams = '';
                // }
                if (filterModel.post_ts) {
                    filter.post_ts = filterModel.post_ts
                }
                if (filterModel.auth_ts) {
                    filter.auth_ts = filterModel.auth_ts
                }

                // Handle balance filter specifically
                if (filterModel.amt) {
                    filter.amt = filterModel.amt
                }
                try {
                    const resp = await fetchTransactions({
                        page: pageNumber,
                        limit: pageSize,
                        type: activeType,
                        filter: filter
                    });
                    if (resp.ok) {
                        const rows = resp.data.transactionRecords;
                        if (rows.length === 0) {
                            gridRef.current.api.showNoRowsOverlay()
                        }
                        if (rows.length !== 0) {
                            gridRef.current.api.hideOverlay()
                        }
                        params.success({
                            rowData: rows,
                        });
                    } else {
                        console.log("Error loading rows..")
                        params.fail();
                    }
                } catch (err) {
                    console.log(err);
                }
            },
        };
    };


    useEffect(() => {
        if (gridRef.current?.api) {
            gridRef.current.api.refreshServerSide({ purge: true });
        }
    }, [activeType]);

    return (
        <div>
            <ButtonGroup className="mb-3">
                <Button
                    className="mr-1"
                    color="primary"
                    onClick={() => setActiveType('achRequests')}
                    active={activeType === 'achRequests'}>
                    ACH Requests
                </Button>
                <Button
                    className="mr-1"
                    color="primary"
                    onClick={() => setActiveType('settled')}
                    active={activeType === 'settled'}>
                    Settled Transactions
                </Button>
                <Button
                    className="mr-1"
                    color="primary"
                    onClick={() => setActiveType('authorizations')}
                    active={activeType === 'authorizations'}>
                    Pending Authorizations
                </Button>
                {/* <Button
                    className="mr-1"
                    color="primary"
                    onClick={() => setActiveType('merchant_credits')}
                    active={activeType === 'merchant_credits'}>
                    Pending Merchant Credits
                </Button> */}

                <Button
                    className="mr-1"
                    color="primary"
                    onClick={() => setActiveType('payments')}
                    active={activeType === 'payments'}>
                    Payments
                </Button>

                <Button
                    className="mr-1"
                    color="primary"
                    onClick={() => setActiveType('all')}
                    active={activeType === 'all'}>
                    All
                </Button>
                <Button
                    className="mr-1"
                    color="primary"
                    onClick={() => setActiveType('userView')}
                    active={activeType === 'userView'}>
                    User View
                </Button>
            </ButtonGroup>

            {loading && <p>Loading transactions...</p>}
            {error && <p className="text-danger">{error}</p>}
            <div className="ag-theme-quartz" style={{ height: '500px', width: '100%' }}>
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs[activeType]}
                    animateRows={true}
                    domLayout="normal"
                    rowModelType={'serverSide'}
                    serverSideDatasource={getTransactionsFromServer()}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={50}
                    cacheBlockSize={50}
                    autoSizeStrategy={{ type: 'fitCellContents' }}
                />
            </div>
        </div>
    );
};

export default TransactionGrid;
