import { useState } from 'react';


const DatePostedFilterPanel = ({ api, column }) => {
    const [selectedValue, setSelectedValue] = useState('');
    const handleFilterChange = (e) => {
        const filterValue = e.target.value;
        setSelectedValue(filterValue);
        const filterModel = {
            filterType: 'date',
            type: 'greaterThan',
            dateFrom: filterValue,
        };
        api.setColumnFilterModel(column, filterModel);
        api.onFilterChanged();
    };
    return (
        <div className={'ag-floating-filter-input'}>
            <input
                className={'ag-input-field ag-text-field'}
                type="date"
                value={selectedValue}
                onChange={(e) => handleFilterChange(e)}
                style={{ borderRadius: '3px', borderColor: '#babfc7', padding: '2px', width: '100%' }}
            />
        </div>
    );
};

export default DatePostedFilterPanel;
