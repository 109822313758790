import { useState } from 'react';

// Transaction type mapping
const transactionTypeMap = {
    'Payment': 'Payment',
    'Visa Settlement': 'Visa Settlement',
    'Adjustment': 'Adjustment',
    'Visa Authorization': 'Visa Authorization',
    'Mastercard Settlement': 'Mastercard Settlement',
    'Visa Authorization Backout': 'Visa Authorization Backout'
    // Add more mappings as needed
};


const TransactionTypeFilterPanel = ({ api, column }) => {
    const [selectedValue, setSelectedValue] = useState('');
    const handleFilterChange = (e) => {
        const filterValue = e.target.value;
        setSelectedValue(filterValue);
        const filterModel = {
            filterType: 'text',
            type: 'contains',
            filter: e.target.value,
        };
        api.setColumnFilterModel(column, filterModel);
        api.onFilterChanged();
    };
    return (
        <div className={'ag-floating-filter-input'}>
            <select
                className={'ag-input-field ag-text-field'}
                value={selectedValue}
                onChange={(e) => handleFilterChange(e)}
                style={{ borderRadius: '3px', borderColor: '#babfc7', padding: '2px', width: '100%' }}>
                <option value=""></option>
                {Object.values(transactionTypeMap).map((item, index) => (
                    <option key={index} value={item}>
                        {item}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default TransactionTypeFilterPanel;
