import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import BaseAPI from '#helpers/base-api.js';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { changeLayoutTitle } from '../../redux/actions.js';
import UserEdit from './components/UserEdit.js';
import EdgeToast from '#components/EdgeToast.js';
import Enums from '../../constants/enums.js';
import { set } from 'date-fns';

const EditUserPage = () => {
    const ebAdminClient = new BaseAPI();
    const { user_id } = useParams();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastTitle, setToastTitle] = useState('');
    const [toastContent, setToastContent] = useState('');
    const toggleToast = () => setShowToast(!showToast);

    async function handleSaveUser(userData) {
        setIsLoading(true);
        try {
            userData.userId = user_id;
            console.log(userData)
            const saveUserResponse = await ebAdminClient.post(`/users/save`, userData);
            console.log('saveUserResponse', saveUserResponse);
            if (saveUserResponse.status === 200) {
                showMessageToast('success', 'Success', 'User saved successfully');
                console.log('User saved successfully');
            } else {
                console.log('Failed to save user');
                showMessageToast('error', 'Error', 'Failed to save user');
            }
        } catch (error) {
            console.error('Failed to save user');
            console.log(error);
            setIsLoading(false);
            showMessageToast('error', 'Error', 'Failed to save user');
            return;
        }
        fetchUser(user_id);
        setIsLoading(false);
    }

    const showMessageToast = (type, title, content) => {
        setToastType(type);
        setToastTitle(title);
        setToastContent(content);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 10000);
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeLayoutTitle('Edit User'));
        fetchUser(user_id);
    }, [user_id]);

    /**
     * Fetches user data from the API for a given user ID.
     *
     * @param {string} user_id - The ID of the user to fetch.
     * @returns {Promise<void>}
     */
    async function fetchUser(user_id) {
        setIsLoading(true);
        try {
            const response = await ebAdminClient.get(`users/view/${user_id}`);
            // console.log(response);
            if (response.data) {
                setUserData(response.data);
            } else {
                console.log('No user found for this id', user_id);
            }
        } catch (error) {
            console.error('Could not find user', user_id, error);
        }
        setIsLoading(false);
    }

    if (isLoading) return <div>Loading...</div>;

    return (
        <div className="py-3">
            <Helmet>
                <title> Edit {userData.email ? `${userData.email} Details` : 'Loading...'}</title>
            </Helmet>

            <UserEdit userData={userData} onSave={handleSaveUser} />
        </div>
    );
};

export default EditUserPage;
