import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { changeLayoutTitle } from '../../redux/actions';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import EdgeToast from '#components/EdgeToast.js';
import { Helmet } from 'react-helmet';
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import BaseAPI from '#helpers/base-api.js';

const monthTextList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const ebAdminClient = new BaseAPI();

const Statements = () => {
    const { user_id } = useParams();
    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastTitle, setToastTitle] = useState('');
    const [toastContent, setToastContent] = useState('');
    const toggleToast = () => setShowToast(!showToast);

    const showMessageToast = (type, title, content) => {
        setToastType(type);
        setToastTitle(title);
        setToastContent(content);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 10000);
    };

    const [monthList, setMonthList] = useState([]);

    const onBackToUserDetails = () => {
        history.push(`/users/view/${user_id}`);
    };

    const downloadStatementHandler = async (elem) => {
        showMessageToast('info', 'Downloading..', 'Preparing Statement for download.')
        const resp = await ebAdminClient.get(`/users/${user_id}/statements?month=${elem.month}`, {}, {}, 'blob')
        const blob = new Blob([resp.data], { type: resp.headers['Content-type'] });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${elem.month}_statement.pdf`;
        a.click();

        window.URL.revokeObjectURL(url);
        showMessageToast('success', 'Download Complete', 'Successfully downloaded statement!')
    }

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeLayoutTitle('Monthly Statements'));
        const loadHistory = async () => {
            try {
                const startResp = await ebAdminClient.get(`fund/${user_id}/start-deposit-month`);
                const { year, month } = startResp.data;
                const currentYear = new Date().getFullYear();
                const availableDay = 1

                const startMonth = new Date(year, month - 1);
                const yearList = [];
                for (let i = currentYear; i >= year; i--) {
                    const items = [];
                    for (let j = 12; j >= 1; j--) {
                        const itemDate = new Date(i, j - 1, availableDay);
                        if (itemDate < startMonth || itemDate > new Date()) continue;
                        items.push({
                            label: `${monthTextList[j - 1]} ${i}`,
                            month: `${i}-${j}`,
                        });
                    }
                    yearList.push({
                        year: i,
                        items,
                    });
                }
                setMonthList(yearList);
            } catch (e) {
                console.error(e)
            }
        };
        loadHistory();
    }, [dispatch, user_id]);

    return (
        <div className="py-3">
            <Helmet>
                <title>Monthly Statements</title>
            </Helmet>
            <div className="mb-3">
                <button className="btn btn-warning" onClick={onBackToUserDetails}>
                    Back To User Details
                </button>
            </div>

            <Card className="shadow border-0">
                <CardHeader>
                    <CardTitle tag="h4" className="mb-0">
                        Monthly Statements
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    {monthList.map((item, index) => (
                        <div key={index} style={{ marginBottom: '20px' }}>
                            <h5 style={{ borderBottom: `1px solid #eef2f7`, fontSize: '16px', color: 'black' }}>
                                {item.year}
                            </h5>
                            <div>
                                {item.items.map((elem, ix) => (
                                    <div key={ix} className="d-flex justify-content-between align-items-center">
                                        <p className="my-auto">{elem.label}</p>
                                        <Button className="" color='primary' onClick={() => downloadStatementHandler(elem)}>Download</Button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                    {!monthList.length && <p>No statements available.</p>}
                </CardBody>
            </Card>
            <EdgeToast
                isOpen={showToast}
                toggle={toggleToast}
                type={toastType}
                title={toastTitle}
                content={toastContent}
            />
        </div>
    )
}

export default Statements