import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Button } from 'reactstrap';

const AdjustBalanceModal = ({ isOpen, toggle, onAdjustBalance }) => {
    const [adjustmentType, setAdjustmentType] = useState('credit');
    const [dollarAmount, setDollarAmount] = useState('');
    const [reason, setReason] = useState('');
    const maxCharacters = 40;
    const handleSubmit = (e) => {
        e.preventDefault();
        const amount = dollarAmount;
        onAdjustBalance(amount, reason, adjustmentType);
        setAdjustmentType('credit');
        setDollarAmount('');
        setReason('');
        toggle();
    };

    const handleReasonUpdate = (e) => {
        setReason(e.target.value)
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Adjust Balance</ModalHeader>
            <Form onSubmit={handleSubmit}>
                <ModalBody>
                    <FormGroup>
                        <Label for="adjustmentType">Debit or Credit?</Label>
                        <Input
                            type="select"
                            name="adjustmentType"
                            id="adjustmentType"
                            value={adjustmentType}
                            onChange={(e) => setAdjustmentType(e.target.value)}
                            required>
                            <option value="credit">Credit</option>
                            <option value="debit">Debit</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="dollarAmount">Amount in Dollars</Label>
                        <Input
                            type="number"
                            name="dollarAmount"
                            id="dollarAmount"
                            placeholder="Enter amount"
                            value={dollarAmount}
                            onChange={(e) => setDollarAmount(e.target.value)}
                            required
                            min="0.01"
                            step="0.01"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="reason">Reason</Label>
                        <Input
                            type="textarea"
                            name="reason"
                            id="reason"
                            placeholder="Enter reason for adjustment"
                            value={reason}
                            onChange={handleReasonUpdate}
                            className={reason.length === 40 ? "border-danger" : ""}
                            maxLength={40}
                            required
                        />
                        <p className={"text-right " + (reason.length === 40 ? "text-danger" : "")}>{reason.length}/{maxCharacters} characters</p>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">
                        Adjust Balance
                    </Button>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default AdjustBalanceModal;
