import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BaseAPI from '#helpers/base-api.js';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { changeLayoutTitle } from '../../redux/actions';
import EdgeToast from '#components/EdgeToast.js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const BankAccountsPage = () => {
    const ebAdminClient = new BaseAPI();
    const { user_id } = useParams();
    const [bankAccounts, setBankAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastTitle, setToastTitle] = useState('');
    const [toastContent, setToastContent] = useState('');
    const toggleToast = () => setShowToast(!showToast);

    const showMessageToast = (type, title, content) => {
        setToastType(type);
        setToastTitle(title);
        setToastContent(content);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 10000);
    };

    const onBackToUserDetails = () => {
        history.push(`/users/view/${user_id}`);
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeLayoutTitle('Bank Accounts'));
        fetchBankAccounts();
    }, [user_id]);

    async function fetchBankAccounts() {
        setIsLoading(true);
        try {
            const response = await ebAdminClient.get(`users/${user_id}/bankaccounts`);
            if (response.data) {
                setBankAccounts(response.data);
            } else {
                console.log('No bank accounts found for this user', user_id);
                showMessageToast('warning', 'No Accounts', 'No bank accounts found for this user');
            }
        } catch (error) {
            console.error('Could not fetch bank accounts', error);
            showMessageToast('error', 'Error', 'Failed to fetch bank accounts');
        }
        setIsLoading(false);
    }

    if (isLoading) return <div>Loading...</div>;

    return (
        <div className="py-3">
            <Helmet>
                <title>Bank Accounts</title>
            </Helmet>
            <div className="mb-3">
                <button className="btn btn-warning" onClick={onBackToUserDetails}>
                    Back To User Details
                </button>
            </div>
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Bank Accounts</h4>
                    {bankAccounts.length > 0 ? (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Bank Name</th>
                                    <th>Account Name</th>
                                    <th>Account Number (last 4 digits)</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bankAccounts.map((account, index) => (
                                    <tr key={index}>
                                        <td>{account.metaBankName}</td>
                                        <td>{account.metaAccountName}</td>

                                        <td>{account.metaAccountMask}</td>
                                        <td>{account.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No bank accounts found</p>
                    )}
                </div>
            </div>

            <EdgeToast
                isOpen={showToast}
                toggle={toggleToast}
                type={toastType}
                title={toastTitle}
                content={toastContent}
            />
        </div>
    );
};

export default BankAccountsPage;
