import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, CardHeader, Button, FormGroup, Label, Input, Spinner, Dropdown } from 'reactstrap';
import EdgeToast from '#components/EdgeToast';
import Enums from '../../../constants/enums';
import BaseAPI from '../../../helpers/base-api';


const baseAPI = new BaseAPI();

const UserEdit = ({ userData, onSave }) => {
    const history = useHistory(); // Hook to enable programmatic navigation
    const displayValue = (value) => value || 'N/A';
    const [tierMap, setTierMap] = useState({});
    const [affiliates, setAffiliates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastTitle, setToastTitle] = useState('');
    const [toastContent, setToastContent] = useState('');

    const [formData, setFormData] = useState({
        // name: userData.name || '',
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        address: userData.address || '',
        city: userData.city || '',
        state: userData.state || '',
        zip: userData.zip || '',
        birthDay: userData.birthDay || '',
        userStatus: userData.status || '',
        boostLevel: userData.boostLevel || '',
        affiliateId: userData.affiliateId || ''
    });

    // Add this useEffect hook
    useEffect(() => {
        console.log(formData)
        setFormData({
            // name: userData.name || '',
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            address: userData.address || '',
            city: userData.city || '',
            state: userData.state || '',
            zip: userData.zip || '',
            birthDay: userData.birthDay || '',
            userStatus: userData.status || '',
            boostLevel: userData.boostLevel || '',
            affiliateId: userData.affiliateId || ''
        });
        console.log(userData)
    }, [userData]);

    useEffect(() => {
        fetchBoostLevels();
        fetchAffiliatesList();
    }, []);

    const fetchAffiliatesList = async () => {
        try {
            const resp = await baseAPI.get('affiliates/list');
            setAffiliates(resp.data)
        } catch (err) {
            console.error(err)
        }
    }

    const fetchBoostLevels = async () => {
        try {
            const response = await baseAPI.get('boostLevels');
            const fetchedData = response.data;
            const newTierMap = fetchedData.reduce((map, item) => {
                map[item.name] = item.label;
                return map;
            }, {});

            setTierMap(newTierMap);
        } catch (error) {
            console.error('Failed to fetch boost levels:', error);
        }
    };

    const handleSave = () => {
        onSave(formData);
    };
    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };

    const goBack = () => {
        history.push(`/users/view/${userData.id}`);
    };

    const toggleToast = () => setShowToast(!showToast);

    const showMessageToast = (type, title, content) => {
        setToastType(type);
        setToastTitle(title);
        setToastContent(content);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 10000);
    };

    const sendVerificationEmail = async () => {
        try {
            setLoading(true);
            // Reset Email Verification to user
            const resp = await baseAPI.post('/auth/reset-2fa-verification', { email: userData.email });
            showMessageToast('success', 'Email Sent!', `Email was successfully sent to ${userData.email}`);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <React.Fragment>
            <Button color="primary" onClick={goBack} style={{ marginBottom: '20px' }}>
                Back to the {userData.email} Details
            </Button>
            <Container className="user-details">
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col md={4}>
                                        <strong>Email:</strong>
                                        <br /> {displayValue(userData.email)}
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="firstName">
                                                <strong>First Name:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="firstName"
                                                id="firstName"
                                                value={formData.firstName}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="lastName">
                                                <strong>Last Name:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="lastName"
                                                id="lastName"
                                                value={formData.lastName}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="address">
                                                <strong>Street:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="address"
                                                id="address"
                                                value={formData.address}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="city">
                                                <strong>City:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="city"
                                                id="city"
                                                value={formData.city}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="state">
                                                <strong>State:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="state"
                                                id="state"
                                                value={formData.state}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="zip">
                                                <strong>Zip:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="zip"
                                                id="zip"
                                                value={formData.zip}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="birthDay">
                                                <strong>DOB:</strong>
                                            </Label>
                                            <Input
                                                type="date"
                                                name="birthDay"
                                                id="birthDay"
                                                value={formData.birthDay}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="status">
                                                <strong>Status:</strong>{' '}
                                            </Label>

                                            <Input
                                                className="d-block form-control"
                                                type="select"
                                                name="userStatus"
                                                id="userStatus"
                                                onChange={handleInputChange}
                                                value={formData.userStatus}
                                                style={{ textTransform: 'uppercase' }}>
                                                {Object.values(Enums.UserStatus).map((item, index) => (
                                                    <option
                                                        key={index}
                                                        value={item}
                                                        style={{ textTransform: 'uppercase' }}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="user-tier">
                                                <strong>Tier:</strong>
                                            </Label>

                                            <Input
                                                className="d-block form-control"
                                                type="select"
                                                name="boostLevel"
                                                id="boostLevel"
                                                onChange={handleInputChange}
                                                value={formData.boostLevel}>
                                                {Object.keys(tierMap).map((item, index) => (
                                                    <option key={index} value={item}>
                                                        {tierMap[item]}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>
                                                <strong>2FA Required:</strong>
                                            </Label>
                                            {userData.require2FA ? (
                                                <i style={{ color: 'green' }} class="ml-2 fa-solid fa-circle-check"></i>
                                            ) : (
                                                <i style={{ color: 'red' }} class="ml-2 fa-solid fa-circle-xmark"></i>
                                            )}
                                            {userData.require2FA && (
                                                <Button
                                                    color="warning"
                                                    className="d-block"
                                                    onClick={sendVerificationEmail}>
                                                    {!loading ? (
                                                        'Reset 2FA Verification'
                                                    ) : (
                                                        <Spinner style={{ height: '1rem', width: '1rem' }}> </Spinner>
                                                    )}
                                                </Button>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label><strong>Affiliate ID: </strong></Label>
                                            <Input
                                                className="d-block form-control"
                                                type="select"
                                                name="affiliateId"
                                                id="affiliateId"
                                                onChange={handleInputChange}
                                                value={formData.affiliateId}>
                                                <option value="">No Affiliate ID</option>
                                                {affiliates.map((item, index) => (
                                                    <option key={index} value={item.affId}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button color="primary" onClick={handleSave}>
                                    Save Changes
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <EdgeToast
                isOpen={showToast}
                toggle={toggleToast}
                type={toastType}
                title={toastTitle}
                content={toastContent}
            />
        </React.Fragment>
    );
};

export default UserEdit;
